@import '@Styles/variables.module.scss';

.name {
  margin: 0;
  height: 24px;
  overflow: hidden;
  width: -moz-fit-content;
  width: fit-content;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  max-width: 100%;
}

.statusContainer {
  display: flex;
  gap: 3px;
  align-items: center;
  font-size: 12px;
}

.icon {
  width: 10px;
  height: 10px;
}

.LIVE {
  color: $FundLiveColor;
  fill: $FundLiveColor;
}

.CLOSED {
  color: $FundClosedColor;
  fill: $FundClosedColor;
}

.IN_DELIVERY {
  color: $FundInDeliveryColor;
  fill: $FundInDeliveryColor;
}

.COMPLETE {
  color: $FundCompleteColor;
  fill: $FundCompleteColor;
}
