@import '@Styles/variables.module.scss';

.container {
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 100%;
}

.titleContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
}

.helpIcon {
  margin-top: auto;
}

.title {
  margin: 0;
}

.amountContainer {
  display: flex;
  gap: 5px;
  align-items: center;
}

.amount {
  margin: 0;
  font-size: 22px;
}
