@import '@Styles/variables.module.scss';

.container {
  width: fit-content;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  font-size: 13px;
}

.distributedContainer {
  width: 200px;
  text-wrap: wrap;
}

.distributedPercentageBar {
  > div {
    background-image: linear-gradient(300deg, $AFFuchsiaPink500 17.16%, $AFNavyBlue300 80.8%);
  }
}

.fundedProjects {
  border-bottom: 1px solid;
  height: fit-content;
}
